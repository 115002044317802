import { SxStyleProp } from 'theme-ui';

export const progressBar: SxStyleProp = {
  margin: '1rem 0',
  width: '100%'
};

export const transactionsContainer: SxStyleProp = {
  paddingTop: '1rem',
  paddingBottom: '2rem',
  maxWidth: '100vw'
};

export const transactionsContainerFlex: SxStyleProp = {
  flexDirection: ['column', 'row'],
  padding: '1rem 0',
  width: '100%',
  alignItems: 'center'
};

export const actionButton: SxStyleProp = {
  marginTop: '1.5rem',
  marginLeft: '1rem',
  marginBottom: ['1rem', '0rem']
};

export const tableHeaderCss: SxStyleProp = {
  fontWeight: 'bold',
  color: 'black',
  textAlign: 'center'
};

export const tableCss: SxStyleProp = {
  fontSize: '14px'
};

export const tableBorderCss: SxStyleProp = {
  borderBottom: '3px solid #C4C4C4'
};
