/**@jsx jsx */
import { Chip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState
} from '@material-ui/lab/Autocomplete';
import { useEffect, useState } from 'react';
import { jsx, SxStyleProp } from 'theme-ui';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//styles
const renderWidth: SxStyleProp = { width: '100%' };

interface MultiSelectProps<T> {
  options: T[];
  allOption: T;
  getOptionLabel: (option: T) => string;
  idProperty: keyof T;
  allOptionsValue: string | number;
  loading?: boolean;
  autocompleteStyle?: string;
}

export function MultiSelect<T>(props: MultiSelectProps<T>) {
  const {
    options,
    allOption,
    getOptionLabel,
    idProperty,
    allOptionsValue,
    loading,
    autocompleteStyle
  } = props;

  const [selectedArray, setSelectedArray] = useState<T[]>([]);

  //checks if all other options are selected
  useEffect(() => {
    if (isElseSelected()) {
      setSelectedArray([allOption, ...options]);
    }
  }, [selectedArray.length]);

  console.log(selectedArray);

  const isAllSelected = selectedArray.some(
    //@ts-ignore
    category => category[idProperty] === allOptionsValue
  );

  const isElseSelected = () => {
    if (!options.length || !selectedArray.length) return false;
    const selectedIds = selectedArray.map(e => e[idProperty]);
    return options.every(x => selectedIds.includes(x[idProperty]));
  };

  const optionSelectedDecider = (option: T, value: T) => {
    return option[idProperty] === value[idProperty];
  };

  const renderOptionInternal = (option: T, state: AutocompleteRenderOptionState) => {
    return (
      <div
        sx={renderWidth}
        onClick={() => {
          //@ts-ignore
          if (option[idProperty] === allOptionsValue) {
            return state.selected
              ? setSelectedArray([])
              : setSelectedArray([allOption, ...options]);
          }
          state.selected
            ? setSelectedArray(
                selectedArray.filter(
                  value =>
                    ![allOptionsValue, option[idProperty]].includes(value[idProperty])
                )
              )
            : setSelectedArray(selectedArray.concat(option));
        }}
      >
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selectedArray.some(value => value[idProperty] === option[idProperty])}
        />
        {getOptionLabel(option)}
      </div>
    );
  };

  const adornmentBuilder = (params: AutocompleteRenderInputParams) => {
    const handleDelete = (optionId: number | string) => () => {
      if (optionId === allOptionsValue) return setSelectedArray([]);
      return setSelectedArray(
        //@ts-ignore
        selectedArray.filter(option => option[idProperty] !== optionId)
      );
    };
    if (isAllSelected || isElseSelected()) {
      params.InputProps.startAdornment = (
        <Chip label="All" onDelete={handleDelete(allOptionsValue)} />
      );
    } else {
      params.InputProps.startAdornment = selectedArray.map(option => {
        //@ts-ignore
        if (option[idProperty] !== allOptionsValue)
          return (
            <Chip
              label={getOptionLabel(option)}
              //@ts-ignore
              key={option[idProperty]}
              //@ts-ignore
              onDelete={handleDelete(option[idProperty] as string)}
            />
          );
      });
    }
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={[allOption, ...options]}
        disableCloseOnSelect
        value={selectedArray}
        disableClearable
        getOptionSelected={optionSelectedDecider}
        getOptionLabel={getOptionLabel}
        renderOption={renderOptionInternal}
        className={autocompleteStyle}
        loading={loading}
        renderInput={params => {
          adornmentBuilder(params);
          return (
            <TextField
              {...params}
              variant="outlined"
              label="Search"
              placeholder="Filters"
            />
          );
        }}
      />
    </div>
  );
}
