import { SxStyleProp } from 'theme-ui';

export const icon: SxStyleProp = {
  fontSize: 20
};

export const message: SxStyleProp = {
  display: 'flex',
  alignItems: 'center'
};

export const iconVariant: SxStyleProp = {
  opacity: 0.9,
  marginRight: '8px'
};

export const variantColor: { [key: string]: SxStyleProp } = {
  success: {
    backgroundColor: '#43a047'
  },
  error: {
    backgroundColor: '#d32f2f'
  },
  info: {
    backgroundColor: '#1976d2'
  },
  warning: {
    backgroundColor: '#ffa000'
  }
};
