/** @jsx jsx */
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import { OrderAddressInfo } from '../../api';
import { AddressPoint, CustomSnackBar, LocationsMap, PosSelect } from '../../components';
import { Pos } from '../../models';
import { formatLocation } from '../../utils';
import * as css from '../salesLocations/salesLocationsCss';
import { useSalesLocations } from '../salesLocations/useSalesLocations';

export function OrdersLocations() {
  let initalDate = new Date();
  const fromDate = initalDate.toISOString().substring(0, 10);

  const getDateTo = (fromDate: Date): string => {
    let dateTo = new Date(fromDate);
    dateTo.setDate(fromDate.getDate() + 1);
    return dateTo.toISOString().substring(0, 10);
  };

  const [pos, setPos] = useState(Pos.Todos);
  const {
    handleQueryAddressesClick,
    isLoading,
    hasClicked,
    addressesToshow,
    error,
    setError
  } = useSalesLocations(pos, fromDate, getDateTo(initalDate));

  useEffect(() => {
    handleQueryAddressesClick();
  }, []);

  const mapToPointsInfo = (addressInfo: OrderAddressInfo): AddressPoint => {
    var latLng = formatLocation(addressInfo?.location);
    return {
      addressStreet: addressInfo?.addressStreet,
      order: addressInfo?.orderReference,
      phoneNumber: addressInfo?.phoneNumber,
      comments: addressInfo?.comments,
      lat: latLng.lat,
      lng: latLng.lng
    };
  };

  return (
    <React.Fragment>
      {error && (
        <CustomSnackBar
          message={error}
          variant="error"
          verticalAlignment="top"
          open={!!error}
          horizontalAlignment="center"
          onClose={() => setError('')}
        />
      )}
      <div sx={css.graphWrapper}>
        <h2 sx={css.graphTitle}>
          Pedidos del día {initalDate.toLocaleDateString('es-AR')}
        </h2>
        <div sx={css.selectorContainer}>
          <PosSelect value={pos} onSelect={p => setPos(p)} showAll />
        </div>
        {isLoading && <CircularProgress sx={css.loader} color="primary" />}

        {hasClicked && (
          <LocationsMap
            loading={isLoading}
            points={addressesToshow.map(a => mapToPointsInfo(a))}
            showPos={false}
          />
        )}
      </div>
    </React.Fragment>
  );
}
