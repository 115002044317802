/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState, Fragment } from 'react';
import { GroupedDataGraphResponse, ReportApi, StockItemWithBranchInfo } from '../../api';
import { loaderCss } from '../../styles';
import { Row } from './tableRow';
import * as css from './stockReportCss';
import { ComparisonBarGraph } from '../../components';

export function StockReport() {
  const [stockData, setStockData] = useState<StockItemWithBranchInfo[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [barGraphData, setBarGraphData] = useState<GroupedDataGraphResponse[]>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getStockReport();
  }, []);

  const getStockReport = async () => {
    try {
      setIsLoading(true);
      const response = await ReportApi.getStockItemsReport('Empanadas');
      if (response) {
        setStockData(response.stockItemsTable);
        setBarGraphData(response.stockItemsGraph);
      }
    } catch (error) {
      enqueueSnackbar(
        'Algo salio mal y no se pudo completar la operacion, intenta mas tarde.',
        { variant: 'error' }
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Container>
        <Grid item xs={12}>
          <Paper sx={css.graphWrapperCss}>
            <div>
              <h4>Control de Stock</h4>
              <ComparisonBarGraph
                keys={
                  barGraphData?.length && barGraphData[0].yData
                    ? barGraphData[0].yData?.map(x => (x.yKey ? x.yKey : ''))
                    : []
                }
                data={barGraphData ?? []}
                showNumbersInColumns
                width={600}
                showLegends
              />
            </div>
            <div sx={css.tableCss}>
              {!!stockData?.length && (
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table" size="small" sx={css.tableCss}>
                    <TableHead>
                      <TableRow sx={css.tableBorderCss}>
                        <TableCell />
                        <TableCell sx={css.tableHeaderCss}>
                          <b>Variedad</b>
                        </TableCell>
                        <TableCell sx={css.tableHeaderCss} align="right">
                          <b>Stock</b>
                        </TableCell>
                        <TableCell sx={css.tableHeaderCss} align="right">
                          <b>Minimo</b>
                        </TableCell>
                        <TableCell sx={css.tableHeaderCss} align="right">
                          <b>Maximo</b>
                        </TableCell>
                        <TableCell sx={css.tableHeaderCss} align="right">
                          <b>Reposicion</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stockData?.map(row => (
                        <Row key={row.name} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </Paper>
        </Grid>
      </Container>
      {isLoading && <CircularProgress sx={loaderCss} color="primary" />}
    </Fragment>
  );
}
