import { SxStyleProp } from 'theme-ui';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import colors from '../../styles/colors';

const drawerWidth = '15rem';

export const root: SxStyleProp = {
  display: 'flex'
};
export const appBar: SxStyleProp = {
  transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
};
export const appBarShift: SxStyleProp = {
  width: `calc(100% - ${drawerWidth})`,
  transition:
    'margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  marginRight: drawerWidth
};
export const title: SxStyleProp = {
  flexGrow: 1
};
export const hide: SxStyleProp = {
  display: 'none'
};

export const toolbar: SxStyleProp = {
  backgroundColor: 'black',
  color: colors.baked
};
export const typography: SxStyleProp = {
  flexGrow: 1,
  marginLeft: '2rem',
  color: colors.baked
};

export const activeStyle: CSSProperties = {
  color: colors.baked,
  fontWeight: 'bold'
};
export const img: SxStyleProp = {
  width: '4rem'
};
