/** @jsx jsx */
import { Button, LinearProgress, Container, Grid } from '@material-ui/core';
import React from 'react';
import { jsx, Flex } from 'theme-ui';
import {
  SalesByDayGraphSection,
  CustomSnackBar,
  Filters,
  Insights,
  UnitSoldSection
} from '../../components';
import { useSales } from './useSales';
import * as css from './salesCss';

export function Dashboard() {
  const initalDate = new Date();
  initalDate.setDate(initalDate.getDate() - 7);
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date>(initalDate);
  const [selectedToDate, setSelectedToDate] = React.useState<Date>(new Date());

  const fromDate = selectedFromDate.toISOString().substring(0, 10);
  const toDate = selectedToDate.toISOString().substring(0, 10);

  const {
    handleQueryClick,
    error,
    setError,
    isLoadingTotals,
    totalUnits,
    totalSales,
    productsSold
  } = useSales(fromDate, toDate);

  return (
    <React.Fragment>
      {error && (
        <CustomSnackBar
          message={error}
          variant="error"
          verticalAlignment="top"
          open={!!error}
          horizontalAlignment="center"
          onClose={() => setError('')}
        />
      )}
      <Container sx={css.salesContainer}>
        <Flex sx={css.salesContainerFlex}>
          <Filters
            selectedFromDate={selectedFromDate}
            setSelectedToDate={setSelectedToDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
          />
          <Grid item lg={2}>
            <Button
              onClick={handleQueryClick}
              sx={css.salesButton}
              color="primary"
              variant="contained"
            >
              CONSULTAR VENTAS
            </Button>
          </Grid>
        </Flex>
        <Grid container>
          {' '}
          {isLoadingTotals && <LinearProgress sx={css.progressBar} />}
        </Grid>
        {!!totalSales.length && !!totalUnits.length && (
          <Insights totalSales={totalSales} totalUnits={totalUnits} />
        )}
        <Grid container spacing={2}>
          <SalesByDayGraphSection
            totalSales={totalSales}
            totalUnits={totalUnits}
            isLoading={isLoadingTotals}
          />
          <UnitSoldSection productsSold={productsSold} isLoading={isLoadingTotals} />
        </Grid>
      </Container>
    </React.Fragment>
  );
}
