import { useState } from 'react';
import { AddressInfo, OrderAddressInfo, StatsApi } from '../../api';
import { Pos } from '../../models';

export function useSalesLocations(pos: Pos, fromDate: string, toDate: string) {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);
  const [addressesBelgrano, setAddressesBelgrano] = useState<OrderAddressInfo[]>([]);
  const [addressesBicentenario, setAddressesBicentenario] = useState<OrderAddressInfo[]>(
    []
  );
  const [addressesGrandBourg, setAddressesGrandBourg] = useState<OrderAddressInfo[]>([]);
  const [addressesSanLorenzo, setAddressesSanLorenzo] = useState<OrderAddressInfo[]>([]);
  const [addressesPaseo, setAddressesPaseo] = useState<OrderAddressInfo[]>([]);

  const handleQueryAddressesClick = async () => {
    try {
      setIsLoading(true);
      setHasClicked(true);
      setAddressesBelgrano([]);
      setAddressesBicentenario([]);
      setAddressesGrandBourg([]);
      setAddressesPaseo([]);
      const bicentenarioAddressesPromise = StatsApi.getSalesLocationsByDay(
        fromDate,
        toDate,
        Pos.Bicentenario
      );

      const belgranoAddressesPromise = StatsApi.getSalesLocationsByDay(
        fromDate,
        toDate,
        Pos.Belgrano
      );
      const gbAddressesPromise = StatsApi.getSalesLocationsByDay(
        fromDate,
        toDate,
        Pos.GrandBourg
      );

      const slAddressesPromise = StatsApi.getSalesLocationsByDay(
        fromDate,
        toDate,
        Pos.SanLorenzo
      );

      const paseSaltaAddressPromise = StatsApi.getSalesLocationsByDay(
        fromDate,
        toDate,
        Pos.ZonaSur
      );

      const [
        belgranoAddresses,
        bicentenarioAddresses,
        gbAddresses,
        slAddresses,
        paseoSaltaAddresses
      ] = await Promise.all([
        belgranoAddressesPromise,
        bicentenarioAddressesPromise,
        gbAddressesPromise,
        slAddressesPromise,
        paseSaltaAddressPromise
      ]);

      setAddressesBelgrano(belgranoAddresses.addresses as AddressInfo[]);
      setAddressesBicentenario(bicentenarioAddresses.addresses as AddressInfo[]);
      setAddressesGrandBourg(gbAddresses.addresses as AddressInfo[]);
      setAddressesSanLorenzo(slAddresses.addresses as AddressInfo[]);
      setAddressesPaseo(paseoSaltaAddresses.addresses as AddressInfo[]);
      setIsLoading(false);
    } catch (error) {
      setError('Ups! Algo salio mal. \n Intenta de nuevo mas tarde 😞');
      setIsLoading(false);
    }
  };

  let addressesToshow: AddressInfo[] = [];
  switch (pos) {
    case Pos.Belgrano:
      addressesToshow = addressesBelgrano;
      break;
    case Pos.Bicentenario:
      addressesToshow = addressesBicentenario;
      break;
    case Pos.GrandBourg:
      addressesToshow = addressesGrandBourg;
      break;
    case Pos.SanLorenzo:
      addressesToshow = addressesSanLorenzo;
      break;
    case Pos.ZonaSur:
      addressesToshow = addressesPaseo;
      break;
    case Pos.Todos:
      addressesToshow = addressesToshow
        .concat(addressesGrandBourg)
        .concat(addressesBicentenario)
        .concat(addressesSanLorenzo)
        .concat(addressesPaseo)
        .concat(addressesBelgrano);
      break;
    default:
      break;
  }

  return {
    handleQueryAddressesClick,
    isLoading,
    hasClicked,
    addressesToshow,
    error,
    setError
  };
}
