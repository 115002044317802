/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody
} from '@material-ui/core';
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { StockItemWithBranchInfo } from '../../api';
import { tableBorderCss, tableHeaderCss } from './stockReportCss';
import { SxStyleProp } from 'theme-ui';

export const unitCss = (min: number, units: number): SxStyleProp => ({
  color: min >= units ? 'red' : 'black'
});

export function Row(props: { row: StockItemWithBranchInfo }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={tableBorderCss}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={tableHeaderCss} component="th" scope="row">
          <b>{row.name?.substr(2, row.name.length)}</b>
        </TableCell>
        <TableCell sx={tableHeaderCss} align="right">
          <b>{row.units}</b>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row?.stockItemBranchInfo?.map(branchInfo => (
                    <TableRow key={branchInfo.branch?.id}>
                      <TableCell />
                      <TableCell component="th" scope="row">
                        {branchInfo?.branch?.name}
                      </TableCell>
                      <TableCell align="right">
                        <span sx={unitCss(branchInfo?.minimumStock, branchInfo?.units)}>
                          {branchInfo?.units}
                        </span>
                      </TableCell>
                      <TableCell align="right">{branchInfo?.minimumStock}</TableCell>
                      <TableCell align="right">{branchInfo?.maximumStock}</TableCell>
                      <TableCell align="right">
                        {branchInfo?.minimumStock > branchInfo?.units
                          ? branchInfo?.minimumStock - branchInfo?.units
                          : 0}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
