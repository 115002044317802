import { SxStyleProp } from 'theme-ui';

export const table: SxStyleProp = {
  width: '100%'
};

export const cheaderCell: SxStyleProp = {
  fontWeight: 'bold',
  color: 'black',
  textAlign: 'center'
};

export const secondCell: SxStyleProp = {
  maxWidth: '9.375rem'
};

export const bodyCell: SxStyleProp = {
  textAlign: 'center',
  maxWidth: '9.375rem',
  overflow: 'hidden'
};

export const centerAlign: SxStyleProp = {
  textAlign: 'center'
};

export const addCategoryFlex: SxStyleProp = {
  justifyContent: 'flex-end',
  pb: '1rem'
};
