export enum Pos {
  Bicentenario = 'BICENTENARIO',
  Belgrano = 'BELGRANO',
  GrandBourg = 'GRANDBOURG',
  SanLorenzo = 'SANLORENZO',
  PaseoSalta = 'PASEO_SALTA',
  ZonaSur = 'ZONA_SUR',
  Factory = 'FABRICA',
  Todos = 'TODOS'
}
