/**@jsx jsx */
import { jsx } from 'theme-ui';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router';
import logo from '../../assets/logo.png';
import { accessPerRole, getUserRole, isAuthorized, login } from '../../utils';
import * as css from './loginCss';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://empanadaslamaite.com.ar">
        Empanadas La Maité
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function Login() {
  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await login(userName, password);
    } catch (error) {
      setErrorMessage('Datos de login incorrectos');
    } finally {
      setIsLoading(false);
    }
  };

  const userRole = getUserRole();
  if (isAuthorized()) return <Redirect to={accessPerRole[userRole][0]} />;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div sx={css.paper}>
        <img src={logo} sx={css.companyLogoCss} alt="Maite" />
        <Typography component="h1" variant="h5">
          Iniciar Sesion
        </Typography>
        <form sx={css.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="user"
            label="Usuario"
            name="user"
            value={userName}
            onChange={e => setUserName(e.target.value)}
            error={!!errorMessage}
            disabled={isLoading}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={e => setPassword(e.target.value)}
            name="password"
            label="Password"
            error={!!errorMessage}
            disabled={isLoading}
            type="password"
            id="password"
          />
          {errorMessage && <p sx={css.error}>{errorMessage}</p>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={css.submit}
          >
            Login
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
