import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

export function useFetchEntityList<T>(
  apiCall: (...args: any) => Promise<T[]>
): [T[], boolean] {
  const [entities, setEntities] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const entitiesFetched = await apiCall();
        setEntities(entitiesFetched);
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Algo salio mal al intentar obtener datos del servidor 😢', {
          variant: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return [entities, isLoading];
}

export function useFetchEntity<T>(
  apiCall: (...args: any) => Promise<T>,
  deps: any[] = []
): [T | null, boolean] {
  const [entities, setEntities] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const entitiesFetched = await apiCall();
        setEntities(entitiesFetched);
      } catch (error) {
        enqueueSnackbar('Algo salio mal al intentar obtener datos del servidor 😢', {
          variant: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, deps);
  console.log('fetching entity and loading', isLoading);
  return [entities, isLoading];
}
