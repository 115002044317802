/** @jsx jsx */
import { Bar } from '@nivo/bar';
import { GroupedDataGraphResponse } from '../../api';
import { SxStyleProp, jsx } from 'theme-ui';
import { Card } from '@material-ui/core';

interface ComparisonBarGraphProps {
  /** Grouped data for the graph, it contains an array with the x objects with each x object will contain its yColumns values.*/
  data: GroupedDataGraphResponse[];
  /**  Y columns names, it represents the columns shown for each grouped object.*/
  keys: string[];
  /**  Grouped is separated columns and stacked shows all the y values in one column. If it is not passed, by default will be grouped.*/
  groupMode?: 'stacked' | 'grouped' | null;
  /** optional Graph width.*/
  width?: number;
  /** optional Graph height.*/
  height?: number;
  /** optional parameter to show the labels in the columns */
  showNumbersInColumns?: boolean;
  /** optional parameter to show legends. Default is false*/
  showLegends?: boolean;
}

const randColor = () => {
  return 'hsl('.concat(Math.round(Math.random() * 360).toString(), ', 70%, 50%)');
};

const generateData = (groupData: GroupedDataGraphResponse): any => {
  let result: { [key: string]: number | string | undefined } = {};
  result['xName'] = groupData.xName;
  if (groupData?.yData) {
    for (var i = 0; i < groupData?.yData?.length; ++i) {
      result[groupData?.yData[i]?.yKey ?? ''] = groupData.yData[i].yValue;
      result[''.concat(groupData.yData[i].yKey ?? '', 'Color')] = randColor();
    }
  }
  return result;
};

const graphWrapper: SxStyleProp = {
  width: ['22rem', 'auto'],
  overflow: 'auto'
};

export function ComparisonBarGraph(props: ComparisonBarGraphProps) {
  const {
    data,
    keys,
    groupMode,
    height,
    width,
    showNumbersInColumns,
    showLegends
  } = props;

  const commonProps = {
    width: width ?? 900,
    height: height ?? 500,
    margin: { top: 60, right: 80, bottom: 60, left: 80 },
    data: data.map(x => generateData(x)),
    indexBy: 'xName',
    keys,
    padding: 0.2,
    labelTextColor: 'inherit:darker(1.4)',
    labelSkipWidth: 16,
    labelSkipHeight: 16
  };
  return (
    <Card sx={graphWrapper}>
      <Bar
        {...commonProps}
        groupMode={groupMode ?? 'grouped'}
        enableLabel={showNumbersInColumns ?? true}
        legends={
          showLegends
            ? [
                {
                  dataFrom: 'keys',
                  anchor: 'top',
                  direction: 'row',
                  translateY: -20,
                  itemWidth: 82,
                  itemHeight: 15,
                  itemsSpacing: 7,
                  symbolSize: 15,
                  itemDirection: 'left-to-right'
                }
              ]
            : []
        }
      />
    </Card>
  );
}
