/**@jsx jsx */
import { jsx } from 'theme-ui';
import * as React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel
} from '@material-ui/core';
import { ProductGraphBar } from '../../models';
import { colors } from '../../utils';
import { SxStyleProp } from 'theme-ui';

interface ProductsSoldTableProps {
  data: ProductGraphBar[];
}

type ColumnSort =
  | 'belgrano'
  | 'bicentenario'
  | 'grandBourg'
  | 'sanLorenzo'
  | 'paseoSalta'
  | 'zonaSur'
  | 'total';

type SortBy = {
  column: ColumnSort;
  desc: boolean;
};

//Component Styles
const tableHeaderBlack: SxStyleProp = {
  fontWeight: 'bold',
  color: 'black'
};

const tableValue = (color: string): SxStyleProp => ({
  color: color
});

const tableHeader = (color: string): SxStyleProp => ({
  fontWeight: 'bold',
  color: color
});

export function ProductsSoldTable(props: ProductsSoldTableProps) {
  const { data } = props;

  const [orderBy, setOrderBy] = React.useState<SortBy>({
    column: 'total',
    desc: true
  });
  const [info, setInfo] = React.useState(data);
  const orderData = (column: ColumnSort) => () => {
    const newData = Array.from(info);
    newData.sort((a, b) =>
      orderBy.desc ? a[column] - b[column] : b[column] - a[column]
    );
    setInfo(newData);
    setOrderBy({ column, desc: !orderBy.desc });
  };
  if (info.length) {
    let belgranoTotal = 0,
      bicentenarioTotal = 0,
      gbTotal = 0,
      sanLorenzoTotal = 0,
      paseoSaltaTotal = 0,
      zonaSurTotal = 0,
      allTotal = 0;
    info.forEach(i => {
      belgranoTotal += i.belgrano;
      bicentenarioTotal += i.bicentenario;
      gbTotal += i.grandBourg;
      sanLorenzoTotal += i.sanLorenzo;
      paseoSaltaTotal += i.paseoSalta;
      zonaSurTotal += i.zonaSur;
      allTotal += i.total;
    });
    console.log('info is', info);
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={tableHeaderBlack}>Pos</TableCell>
            <TableCell sx={tableHeaderBlack}>Variedad</TableCell>
            <TableCell align="right" sx={tableHeader(colors.BELGRANO)}>
              <TableSortLabel
                active={orderBy.column === 'belgrano'}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData('belgrano')}
              >
                Belgrano
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeader(colors.BICENTENARIO)}>
              <TableSortLabel
                active={orderBy.column === 'bicentenario'}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData('bicentenario')}
              >
                Bicentenario
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeader(colors.GRANDBOURG)}>
              <TableSortLabel
                active={orderBy.column === 'grandBourg'}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData('grandBourg')}
              >
                Grand Bourg
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeader(colors.SANLORENZO)}>
              <TableSortLabel
                active={orderBy.column === 'sanLorenzo'}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData('sanLorenzo')}
              >
                San Lorenzo
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeader(colors.PASEO_SALTA)}>
              <TableSortLabel
                active={orderBy.column === 'paseoSalta'}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData('paseoSalta')}
              >
                Paseo Salta
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeader(colors.ZONA_SUR)}>
              <TableSortLabel
                active={orderBy.column === 'zonaSur'}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData('zonaSur')}
              >
                Zona Sur
              </TableSortLabel>
            </TableCell>
            <TableCell align="right" sx={tableHeaderBlack}>
              <TableSortLabel
                active={orderBy.column === 'total'}
                direction={orderBy.desc ? 'desc' : 'asc'}
                onClick={orderData('total')}
              >
                Total
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {info.map((row, i) => (
            <TableRow key={row.reference} hover>
              <TableCell component="th" scope="row">
                {i + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.BELGRANO)}>
                {row.belgrano}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.BICENTENARIO)}>
                {row.bicentenario}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.GRANDBOURG)}>
                {row.grandBourg}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.SANLORENZO)}>
                {row.sanLorenzo}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.PASEO_SALTA)}>
                {row.paseoSalta}
              </TableCell>
              <TableCell align="right" sx={tableValue(colors.ZONA_SUR)}>
                {row.zonaSur}
              </TableCell>
              <TableCell align="right">
                {row.bicentenario +
                  row.belgrano +
                  row.grandBourg +
                  row.sanLorenzo +
                  row.paseoSalta +
                  row.zonaSur}
              </TableCell>
            </TableRow>
          ))}
          <TableRow hover>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row">
              TOTALES
            </TableCell>
            <TableCell align="right">{belgranoTotal}</TableCell>
            <TableCell align="right">{bicentenarioTotal}</TableCell>
            <TableCell align="right">{gbTotal}</TableCell>
            <TableCell align="right">{sanLorenzoTotal}</TableCell>
            <TableCell align="right">{paseoSaltaTotal}</TableCell>
            <TableCell align="right">{zonaSurTotal}</TableCell>
            <TableCell align="right">{allTotal}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
  return null;
}
