/** @jsx jsx */
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Flex, jsx } from 'theme-ui';
import { StatsApi, TransactionRecord } from '../../api';
import { Filters, PosSelect } from '../../components';
import { PaymentType, paymentTypeNames } from '../../const';
import { Pos } from '../../models';
import { formatDateAndTime } from '../../utils';
import * as css from './salesCss';

export function TransactionReport() {
  const initalDate = new Date();
  initalDate.setDate(initalDate.getDate() - 1);
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date>(initalDate);
  const [selectedToDate, setSelectedToDate] = React.useState<Date>(new Date());
  const [transactionData, setTransactionData] = React.useState<TransactionRecord[]>([]);
  const [pos, setPos] = React.useState<Pos>(Pos.Belgrano);
  const [isLoading, setIsLoading] = React.useState(false);
  const fromDate = selectedFromDate.toISOString().substring(0, 10);
  const toDate = selectedToDate.toISOString().substring(0, 10);

  const handlePosChange = (pos: Pos) => {
    setPos(pos);
  };

  const { enqueueSnackbar } = useSnackbar();

  const getStocks = async () => {
    try {
      setIsLoading(true);
      const transactions = await StatsApi.getTransactionsReport(
        fromDate,
        toDate,
        pos as string
      );
      setTransactionData(transactions);
    } catch (error) {
      enqueueSnackbar('Algo salio mal 😢', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };
  let amountOut = 0;
  let totalCcard = 0;
  let totalCashIn = 0;
  let totalCash = 0;
  return (
    <React.Fragment>
      <Container sx={css.transactionsContainer}>
        <Flex sx={css.transactionsContainerFlex}>
          <Filters
            selectedFromDate={selectedFromDate}
            setSelectedToDate={setSelectedToDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
          />
          <div sx={{ ml: [0, '1rem'], mt: ['1rem', 0] }}>
            <PosSelect onSelect={handlePosChange} value={pos} />
          </div>
          <Grid item lg={2}>
            <Button
              onClick={getStocks}
              sx={css.actionButton}
              color="primary"
              variant="contained"
            >
              CONSULTAR
            </Button>
          </Grid>
        </Flex>
        <Grid container> {isLoading && <LinearProgress sx={css.progressBar} />}</Grid>

        {!!transactionData.length && (
          <Grid container spacing={2} sx={{ pt: '1rem' }}>
            <TableContainer component={Paper} sx={{ maxHeight: '70vh' }}>
              <Table
                stickyHeader
                aria-label="collapsible table"
                size="small"
                sx={css.tableCss}
              >
                <TableHead>
                  <TableRow sx={css.tableBorderCss}>
                    <TableCell align="left">
                      <b>Fecha</b>
                    </TableCell>
                    <TableCell sx={css.tableHeaderCss} align="right">
                      <b>Responsable</b>
                    </TableCell>
                    <TableCell sx={css.tableHeaderCss} align="right">
                      <b>Tipo de movimiento</b>
                    </TableCell>
                    <TableCell sx={css.tableHeaderCss} align="right">
                      <b>Observacion</b>
                    </TableCell>
                    <TableCell sx={css.tableHeaderCss} align="right">
                      <b>Monto</b>
                    </TableCell>
                    <TableCell sx={css.tableHeaderCss} align="right">
                      <b>Ticket</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionData.map((t, i) => {
                    if (t.paymentType === PaymentType.CASH_OUT)
                      amountOut = amountOut + Math.abs(t.paymentAmount);
                    if (t.paymentType === PaymentType.CREDIT_CARD)
                      totalCcard = totalCcard + Math.abs(t.paymentAmount);
                    if (t.paymentType === PaymentType.CASH_IN)
                      totalCashIn = totalCashIn + Math.abs(t.paymentAmount);
                    if (t.paymentType === PaymentType.CASH)
                      totalCash = totalCash + Math.abs(t.paymentAmount);
                    return (
                      <TableRow>
                        <TableCell>{formatDateAndTime(t.transactionDate)}</TableCell>
                        <TableCell align="center">{t.transactionAuthor ?? '-'}</TableCell>
                        <TableCell align="center">
                          {paymentTypeNames[t.paymentType as string] ?? t.paymentType}
                        </TableCell>
                        <TableCell align="center">{t.paymentNotes ?? '-'}</TableCell>
                        <TableCell align="center">${t.paymentAmount}</TableCell>
                        <TableCell align="center">
                          {!!t.ticketNumber ? t.ticketNumber : '-'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <TableRow
                sx={{ backgroundColor: '#efefef', fontWeight: 'bold', width: '100%' }}
              >
                <TableCell>
                  <b> Efectivo: ${totalCash} </b>
                </TableCell>
                <TableCell>
                  <b>Tarjeta: ${totalCcard}</b>
                </TableCell>
                {!!totalCashIn && (
                  <TableCell>
                    <b>Ingreso caja: ${totalCashIn}</b>
                  </TableCell>
                )}
                <TableCell>
                  <b>Total ingreso: ${totalCash + totalCcard + totalCashIn}</b>
                </TableCell>
                <TableCell>
                  <b>Salida caja: ${amountOut}</b>
                </TableCell>
                <TableCell>
                  <b>Balance: ${totalCash + totalCcard + totalCashIn - amountOut}</b>
                </TableCell>
              </TableRow>
            </TableContainer>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  );
}
