/**@jsx jsx */
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  ICreateExpenseRequest,
  IExpenseDTO,
  ExpensesApi,
  CreateExpenseRequest,
  UpdateExpenseRequest
} from '../../../api';
import { useExpensesState } from '../../../contexts';
import { getTodayDate } from '../../../utils';
import { useSnackbar } from 'notistack';

export function useExpensesRHF(
  selectedExpense?: IExpenseDTO,
  onSubmit?: (expense: IExpenseDTO) => void
) {
  const [expState] = useExpensesState();
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape<ICreateExpenseRequest>({
    expenseDate: yup.date().required('Requerido'),
    description: yup.string().max(150, 'No mas de 150 caracteres') as yup.Schema<string>,
    amount: yup.number().typeError('Monto debe ser un numero').required('Requerido'),
    expenseCategoryId: yup
      .mixed()
      .required('Debe elegir una categoria')
      .test('validCategory', 'Categoria invalida', val => {
        if (!val) return false;
        return expState.categories.values.map(c => c.id).includes(val);
      }) as yup.MixedSchema<number>,
    branchId: yup
      .mixed()
      .required('Debe elegir una sucursal')
      .test('validBranch', 'Sucursal invalida', val => {
        if (!val) return false;
        return expState.branches.values.map(c => c.id).includes(val);
      }) as yup.MixedSchema<number>
  });

  const hookform = useForm({
    mode: 'onBlur',
    defaultValues: {
      expenseDate: selectedExpense?.expenseDate ?? getTodayDate(),
      amount: selectedExpense?.amount ?? (('' as unknown) as number),
      description: selectedExpense?.description ?? '',
      expenseCategoryId: selectedExpense?.category.id ?? (('' as unknown) as number),
      branchId: selectedExpense?.branch.id ?? (('' as unknown) as number)
    },
    resolver: yupResolver(schema),
    shouldFocusError: true
  });

  const onFormSubmit = async (values: ICreateExpenseRequest) => {
    let updatedExpense: IExpenseDTO;
    try {
      if (!selectedExpense?.id) {
        updatedExpense = await ExpensesApi.createExpense(
          new CreateExpenseRequest(values)
        );
      } else {
        const request = new UpdateExpenseRequest();
        request.init({
          ...values,
          expenseId: selectedExpense.id
        });

        updatedExpense = await ExpensesApi.updateExpense(request);
      }
      enqueueSnackbar(
        `Datos ${!selectedExpense?.id ? 'guardados' : 'modificados'} con exito`,
        {
          variant: 'success'
        }
      );
      if (onSubmit) onSubmit(updatedExpense);
      hookform.reset();
    } catch (error) {
      console.error(hookform.errors);
      enqueueSnackbar('Algo salio mal al intentar guardar los datos 😢', {
        variant: 'error'
      });
    }
  };

  return { onFormSubmit, expState, hookform };
}
