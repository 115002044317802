/** @jsx jsx */
import { CircularProgress } from '@material-ui/core';
import { jsx } from 'theme-ui';
import { ExpensesCategoriesTable } from '../../components';
import { useInitializeExpenses } from '../../hooks';
import { loaderCss } from '../../styles';
import { SxStyleProp } from 'theme-ui';

const expensesCategoryContainer: SxStyleProp = {
  minHeight: '30rem',
  width: ['100%', '100%', '50rem'],
  padding: ['.5rem', '2rem'],
  margin: '0 auto'
};
const expensesCategoryH1: SxStyleProp = {
  textAlign: 'center',
  mt: 0
};

export function ExpensesCategories() {
  const expState = useInitializeExpenses();

  return (
    <div sx={expensesCategoryContainer}>
      <h1 sx={expensesCategoryH1}>Categorias de costos</h1>
      {expState.categories.isLoading && (
        <CircularProgress color="primary" sx={loaderCss} />
      )}
      <ExpensesCategoriesTable />
    </div>
  );
}
