/** @jsx jsx */
import {
  Badge,
  Divider,
  Drawer,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  List,
  ListItemText,
  MenuItem,
  useTheme
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { jsx } from 'theme-ui';
import { Routes } from '../../const';
import { accessPerRole, getUserRole, removeAuthToken } from '../../utils';
import * as css from './menuDrawerCss';

interface MenuDrawerProps {
  open: boolean;
  handleDrawerClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function MenuDrawer(props: MenuDrawerProps) {
  const { open, handleDrawerClose } = props;
  const theme = useTheme();
  const userRole = getUserRole();

  return (
    <Drawer sx={css.drawer} variant="persistent" anchor="right" open={open}>
      <div sx={css.drawerHeader}>
        <IconButton sx={css.button} onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {accessPerRole[userRole].includes(Routes.SalesLocations) && (
          <ExpansionPanel sx={css.expansionPanel}>
            <ExpansionPanelSummary
              sx={css.summary}
              expandIcon={<ExpandMoreIcon sx={css.expandButton} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemText primary="Ventas" />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails sx={css.details}>
              <NavLink
                to={Routes.SalesLocations}
                exact
                activeStyle={css.activeStyle}
                sx={css.navLinkCss}
              >
                <MenuItem>
                  <ListItemText primary="Geolocalizaciones" />
                </MenuItem>
              </NavLink>
              {accessPerRole[userRole].includes(Routes.MonthlySales) && (
                <NavLink
                  to={Routes.MonthlySales}
                  exact
                  activeStyle={css.activeStyle}
                  sx={css.navLinkCss}
                >
                  <MenuItem>
                    <ListItemText primary="Mensuales" />
                  </MenuItem>
                </NavLink>
              )}
              {accessPerRole[userRole].includes(Routes.Dashboard) && (
                <NavLink
                  to={Routes.Dashboard}
                  exact
                  activeStyle={css.activeStyle}
                  sx={css.navLinkCss}
                >
                  <MenuItem>
                    <ListItemText primary="Diarias" />
                  </MenuItem>
                </NavLink>
              )}
              {accessPerRole[userRole].includes(Routes.Comparisons) && (
                <NavLink
                  to={Routes.Comparisons}
                  exact
                  activeStyle={css.activeStyle}
                  sx={css.navLinkCss}
                >
                  <MenuItem>
                    <ListItemText primary="Comparaciones" />
                  </MenuItem>
                </NavLink>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {accessPerRole[userRole].includes(Routes.Expenses) && (
          <ExpansionPanel sx={css.expansionPanel}>
            <ExpansionPanelSummary
              sx={css.summary}
              expandIcon={<ExpandMoreIcon sx={css.expandButton} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemText primary="Costos" />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails sx={css.details}>
              {accessPerRole[userRole].includes(Routes.ExpensesCategories) && (
                <NavLink
                  to={Routes.ExpensesCategories}
                  exact
                  activeStyle={css.activeStyle}
                  sx={css.navLinkCss}
                >
                  <MenuItem>
                    <ListItemText primary="Categorias de costos" />
                  </MenuItem>
                </NavLink>
              )}
              {accessPerRole[userRole].includes(Routes.Expenses) && (
                <NavLink
                  to={Routes.Expenses}
                  exact
                  activeStyle={css.activeStyle}
                  sx={css.navLinkCss}
                >
                  <MenuItem>
                    <ListItemText primary="Gestion de Costos" />
                  </MenuItem>
                </NavLink>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        {accessPerRole[userRole].includes(Routes.OrdersLocations) && (
          <NavLink
            to={Routes.OrdersLocations}
            exact
            activeStyle={css.activeStyle}
            sx={css.navLinkCss}
          >
            <MenuItem sx={css.betaMenuItem}>
              <Badge badgeContent="Beta!" color="secondary">
                <ListItemText primary="Pedidos del dia" />
              </Badge>
            </MenuItem>
          </NavLink>
        )}
        {accessPerRole[userRole].includes(Routes.Stock) && (
          <NavLink
            to={Routes.Stock}
            exact
            activeStyle={css.activeStyle}
            sx={css.navLinkCss}
          >
            <MenuItem>
              <ListItemText primary="Control de Stock" />
            </MenuItem>
          </NavLink>
        )}

        {accessPerRole[userRole].includes(Routes.GeneralReport) && (
          <NavLink
            to={Routes.GeneralReport}
            exact
            activeStyle={css.activeStyle}
            sx={css.navLinkCss}
          >
            <MenuItem sx={css.betaMenuItem}>
              <Badge badgeContent="Beta!" color="secondary">
                <ListItemText primary="Reporte General" />
              </Badge>
            </MenuItem>
          </NavLink>
        )}

        {accessPerRole[userRole].includes(Routes.TransactionsReport) && (
          <NavLink
            to={Routes.TransactionsReport}
            exact
            activeStyle={css.activeStyle}
            sx={css.navLinkCss}
          >
            <MenuItem sx={css.betaMenuItem}>
              <Badge badgeContent="Beta!" color="secondary">
                <ListItemText primary="Transacciones" />
              </Badge>
            </MenuItem>
          </NavLink>
        )}

        {accessPerRole[userRole].includes(Routes.PaymentsReport) && (
          <NavLink
            to={Routes.PaymentsReport}
            exact
            activeStyle={css.activeStyle}
            sx={css.navLinkCss}
          >
            <MenuItem sx={css.betaMenuItem}>
              <Badge badgeContent="Beta!" color="secondary">
                <ListItemText primary="Pagos" />
              </Badge>
            </MenuItem>
          </NavLink>
        )}

        <NavLink
          to={Routes.Login}
          onClick={removeAuthToken}
          exact
          activeStyle={css.activeStyle}
          sx={css.navLinkCss}
        >
          <MenuItem>
            <ListItemText primary="Cerrar sesion" />
          </MenuItem>
        </NavLink>
      </List>
    </Drawer>
  );
}
