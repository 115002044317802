/**@jsx jsx */
import { Button, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { Flex, jsx } from 'theme-ui';
import { IBranchDTO, IExpenseCategoryDTO, IExpenseDTO } from '../../../api';
import { Combobox, DateInput } from '../../../components';
import { useExpensesRHF } from './useExpensesRHF';
import * as css from './expenseFormCss';

interface ExpensesCategoriesFormProps {
  selectedExpense?: IExpenseDTO;
  onSubmit?: (expense: IExpenseDTO) => void;
}

export function ExpensesForm(props: ExpensesCategoriesFormProps) {
  const { selectedExpense, onSubmit } = props;
  const { onFormSubmit, expState, hookform } = useExpensesRHF(selectedExpense, onSubmit);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState,
    errors
  } = hookform;

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} sx={css.formCss}>
      <Controller
        control={control}
        name="branchId"
        render={props => (
          <Combobox
            name="branchId"
            options={expState.branches.values}
            getOptionLabel={(c: IBranchDTO) => c?.name ?? ''}
            defaultValue={selectedExpense?.category}
            onChange={(e, v) => {
              props.onChange(v?.id ?? undefined);
            }}
            value={expState.branches.values.find(v => v.id === props.value) ?? ''}
            placeholder="Seleccionar sucursal"
            label="Sucursal"
            sx={css.formOptionCss}
            noOptionsText="No hay sucursales"
            loading={expState.branches.isLoading}
            error={!!errors.branchId}
            helperText={errors.branchId?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="expenseCategoryId"
        render={props => (
          <Combobox
            name="expenseCategoryId"
            {...props}
            options={expState.categories.values}
            getOptionLabel={(c: IExpenseCategoryDTO) => c?.name ?? ''}
            onChange={(e, v) => {
              props.onChange(v?.id ?? undefined);
            }}
            value={expState.categories.values.find(v => v.id === props.value) ?? ''}
            placeholder="Seleccionar categoria madre"
            label="Categoria"
            sx={css.formOptionCss}
            noOptionsText="No hay categorias"
            defaultValue={selectedExpense?.category}
            loading={expState.categories.isLoading}
            error={!!errors.expenseCategoryId}
            helperText={errors.expenseCategoryId?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="expenseDate"
        render={props => (
          <DateInput
            {...props}
            label="Fecha"
            onChange={d => setValue('expenseDate', d as Date)}
            value={getValues('expenseDate')}
            sx={css.formOptionCss}
          />
        )}
      />
      {formState.touched.expenseDate ? errors.expenseDate?.message : undefined}
      <TextField
        name="amount"
        fullWidth
        label="Monto"
        variant="outlined"
        type="number"
        inputRef={register}
        sx={css.formOptionCss}
        error={!!errors.amount}
        helperText={errors.amount?.message}
      />
      <TextField
        name="description"
        inputRef={register}
        fullWidth
        label="Description"
        variant="outlined"
        multiline
        rows={3}
        sx={{ ...css.formOptionCss, ...css.descriptionWidth }}
        error={!!errors.description}
        helperText={errors.description?.message}
      />
      <Flex sx={css.flexEnd}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={formState.isSubmitting || !formState.isDirty}
        >
          Guardar
        </Button>
      </Flex>
    </form>
  );
}
