/** @jsx jsx */
import { jsx, SxStyleProp } from 'theme-ui';
import { HighLightedTable } from '../../components';
import { HighLight } from '../../models/highlight';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { GroupedDataGraphResponse } from '../../api';
import { generalColors } from '../../utils';

interface HighlightsBalanceProps {
  data: GroupedDataGraphResponse[];
}

const balanceCss: SxStyleProp = (balance: number) => ({
  color: balance > 0 ? generalColors.SUCCESS : generalColors.FAIL
});

export const HighlightsBalance = (props: HighlightsBalanceProps) => {
  const { data } = props;
  if (data && !!data.length) {
    //calculate total income and expenses/outcome
    let income = 0;
    data.forEach(x => {
      income += x.yData?.filter(y => y.yKey === 'Ingresos')[0].yValue ?? 0;
    });
    let expenses = 0;
    data.forEach(x => {
      expenses += x.yData?.filter(y => y.yKey === 'Egresos')[0].yValue ?? 0;
    });
    let balance = income - expenses;

    const getArrow = (balance: number) => {
      if (balance > 0) {
        return <ArrowUpward sx={{ fontSize: 'inherit' }} />;
      }
      return <ArrowDownward sx={{ fontSize: 'inherit' }} />;
    };

    const highlights: HighLight[] = [
      { title: 'Ingresos', value: `\$${income}` },
      { title: 'Egresos', value: `\$${expenses}` },
      {
        title: 'Balance',
        value: (
          <span sx={balanceCss(balance)}>
            {getArrow(balance)}${balance}
          </span>
        )
      }
    ];

    return <HighLightedTable highlights={highlights} />;
  }
  return null;
};
