const colors = {
  handmade: '#fcc751',
  baked: '#d18b0a',
  black: '#000000',
  white: '#ffffff',
  darkGray: '#605F5F',
  success: '#43a047',
  info: '#000000',
  warning: '#ffee58',
  danger: '#f44336',
  products: {
    SA: '#d18b0a',
    CA: '#be282f',
    NA: '#e12327',
    SI: '#c44590',
    MC: '#ff61aa',
    CH: '#f0911f',
    AR: '#7e522f',
    QE: '#d18b0a',
    VE: '#1b8e3f',
    MA: '#c26032',
    AT: '#c58435',
    JQ: '#0ea29b',
    FZ: '#949496',
    PO: '#92827b',
    CQ: '#800080'
  }
};
export default colors;
