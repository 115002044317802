/** @jsx jsx */
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import { StatsApi, StockItem } from '../../../api';
import { Pos } from '../../../models';
import { graphTitleCss, loaderCss } from '../../../styles';
import { PosSelect } from '../../posSelect';
import * as css from './stockLevelSectionCss';

export function StockLevelsSection() {
  const [isLoading, setIsLoading] = useState(false);
  const [pos, setPos] = useState(Pos.Belgrano);
  const [stock, setStock] = useState<StockItem[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const getStocks = async () => {
    try {
      setIsLoading(true);
      const stockLevel = await StatsApi.getStockLevel(pos);
      setStock(stockLevel.filter(s => s.name));
    } catch (error) {
      enqueueSnackbar('Algo salio mal 😢', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePosChange = (pos: Pos) => {
    setPos(pos);
    setStock([]);
  };

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Paper sx={css.container}>
        <h2 sx={graphTitleCss}>Control de Stock</h2>
        <div sx={css.select}>
          <PosSelect onSelect={handlePosChange} value={pos} />
          <Button
            onClick={getStocks}
            sx={css.stockButtonMargin}
            color="primary"
            variant="contained"
          >
            CONSULTAR STOCK
          </Button>
        </div>
        {isLoading && <CircularProgress color="primary" sx={loaderCss} />}
        {!!stock.length && (
          <React.Fragment>
            <h2>Stock {pos.toString()} </h2>
            <div sx={css.tableDiv}>
              <Table size="small" sx={css.stockSmallTable}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={css.cell}>Variedad</TableCell>
                    <TableCell sx={css.cell}>Stock</TableCell>
                    <TableCell sx={css.cell}>Reposicion</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stock.map((row, i) => {
                    return (
                      <TableRow key={row.name} hover>
                        <TableCell component="th" scope="row">
                          {row?.name?.replace('1 ', '')}
                        </TableCell>

                        <TableCell sx={css.cellAlignCenter} align="right">
                          <strong>{row.units}</strong>
                        </TableCell>

                        <TableCell sx={css.cellAlignCenter} align="right">
                          {row.maximumStock > row.units ? '-' : '+'}
                          {Math.abs(row.maximumStock - row.units)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </React.Fragment>
        )}
      </Paper>
    </Grid>
  );
}
