/**@jsx jsx */
import { Grid, Paper } from '@material-ui/core';
import * as React from 'react';
import { jsx } from 'theme-ui';
import { SalesByDay } from '../../api';
import { Pos } from '../../models';
import * as css from './insightsCss';

interface InisghtsProps {
  totalSales: SalesByDay[];
  totalUnits: SalesByDay[];
}

function calculateInsights(totals: SalesByDay[], pos?: Pos) {
  let totalNum = 0;
  const daysCount = totals[0]?.sales?.length ?? 0;
  let newTotals = totals;
  if (pos) {
    newTotals = totals.filter(x => x.pointOfSale === pos);
  }
  newTotals.forEach(saleByDay => {
    saleByDay?.sales?.forEach(day => {
      if (day) totalNum = totalNum + (day?.total ?? 0);
    });
  });

  const avg = Math.round(totalNum / daysCount);
  const total = Math.round(totalNum);
  return { total, avg };
}

export function Insights(props: InisghtsProps) {
  const { totalSales, totalUnits } = props;
  const allSalesInsights = calculateInsights(totalSales);
  const allUnitsInsights = calculateInsights(totalUnits);
  const belgranoSalesInsights = calculateInsights(totalSales, Pos.Belgrano);
  const belgranoUnitsInsights = calculateInsights(totalUnits, Pos.Belgrano);
  const bicentenarioSalesInsights = calculateInsights(totalSales, Pos.Bicentenario);
  const bicentenarioUnitsInsights = calculateInsights(totalUnits, Pos.Bicentenario);
  const gbSalesInsights = calculateInsights(totalSales, Pos.GrandBourg);
  const gbUnitsInsights = calculateInsights(totalUnits, Pos.GrandBourg);
  const sanLorenzoSalesInsights = calculateInsights(totalSales, Pos.SanLorenzo);
  const sanLorenzoUnitsInsights = calculateInsights(totalUnits, Pos.SanLorenzo);
  const paseoSaltaSalesInsights = calculateInsights(totalSales, Pos.PaseoSalta);
  const paseoSaltaUnitsInsights = calculateInsights(totalUnits, Pos.PaseoSalta);
  const zonaSurSalesInsights = calculateInsights(totalSales, Pos.ZonaSur);
  const zonaSurUnitsInsights = calculateInsights(totalUnits, Pos.ZonaSur);

  return (
    <Grid container spacing={1} sx={css.container}>
      <Grid item lg={3} md={6} xs={12}>
        <h3 sx={css.title}>facturacion total</h3>
        <Grid container spacing={1}>
          <Insight color="totals">${allSalesInsights.total}</Insight>
          <Insight color="belgrano" title="Belgrano">
            ${belgranoSalesInsights.total}
          </Insight>
          <Insight color="bicentenario" title="Bicentenario">
            ${bicentenarioSalesInsights.total}
          </Insight>
          <Insight color="grandBourg" title="G. Bourg">
            ${gbSalesInsights.total}
          </Insight>
          <Insight color="sanLorenzo" title="San Lorenzo">
            ${sanLorenzoSalesInsights.total}
          </Insight>
          <Insight color="paseoSalta" title="Paseo Salta">
            ${paseoSaltaSalesInsights.total}
          </Insight>
          <Insight color="zonaSur" title="Zona Sur">
            ${zonaSurSalesInsights.total}
          </Insight>
        </Grid>
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <h3 sx={css.title}>facturacion promedio</h3>
        <Grid container spacing={1}>
          <Insight color="totals">${allSalesInsights.avg}</Insight>
          <Insight color="belgrano" title="Belgrano">
            ${belgranoSalesInsights.avg}
          </Insight>
          <Insight color="bicentenario" title="Bicentenario">
            ${bicentenarioSalesInsights.avg}
          </Insight>
          <Insight color="grandBourg" title="G. Bourg">
            ${gbSalesInsights.avg}
          </Insight>
          <Insight color="sanLorenzo" title="San Lorenzo">
            ${sanLorenzoSalesInsights.avg}
          </Insight>
          <Insight color="paseoSalta" title="Paseo Salta">
            ${paseoSaltaSalesInsights.avg}
          </Insight>
          <Insight color="zonaSur" title="ZonaSur">
            ${zonaSurSalesInsights.avg}
          </Insight>
        </Grid>
      </Grid>

      <Grid item lg={3} md={6} xs={12}>
        <h3 sx={css.title}>unidades vendidas total</h3>
        <Grid container spacing={1}>
          <Insight color="totals">{allUnitsInsights.total}</Insight>
          <Insight color="belgrano" title="Belgrano">
            {belgranoUnitsInsights.total}
          </Insight>
          <Insight color="bicentenario" title="Bicentenario">
            {bicentenarioUnitsInsights.total}
          </Insight>
          <Insight color="grandBourg" title="G. Bourg">
            {gbUnitsInsights.total}
          </Insight>
          <Insight color="sanLorenzo" title="San Lorenzo">
            {sanLorenzoUnitsInsights.total}
          </Insight>
          <Insight color="paseoSalta" title="Paseo Salta">
            {paseoSaltaUnitsInsights.total}
          </Insight>
          <Insight color="zonaSur" title="Zona Sur">
            {zonaSurUnitsInsights.total}
          </Insight>
        </Grid>
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <h3 sx={css.title}>unidades vendidas promedio</h3>
        <Grid container spacing={1}>
          <Insight color="totals">{allUnitsInsights.avg}</Insight>
          <Insight color="belgrano" title="Belgrano">
            {belgranoUnitsInsights.avg}
          </Insight>
          <Insight color="bicentenario" title="Bicentenario">
            {bicentenarioUnitsInsights.avg}
          </Insight>
          <Insight color="grandBourg" title="G. Bourg">
            {gbUnitsInsights.avg}
          </Insight>
          <Insight color="sanLorenzo" title="San Lorenzo">
            {sanLorenzoUnitsInsights.avg}
          </Insight>
          <Insight color="paseoSalta" title="Paseo Salta">
            {paseoSaltaUnitsInsights.avg}
          </Insight>
          <Insight color="zonaSur" title="Zona Sur">
            {zonaSurUnitsInsights.avg}
          </Insight>
        </Grid>
      </Grid>
    </Grid>
  );
}

export type InsightColors =
  | 'totals'
  | 'belgrano'
  | 'bicentenario'
  | 'grandBourg'
  | 'paseoSalta'
  | 'zonaSur'
  | 'sanLorenzo';

interface InsightProps {
  title?: string;
  color: InsightColors;
  children: React.ReactNode;
}

function Insight(props: InsightProps) {
  const { title, color, children } = props;

  return (
    <Grid item lg={css.celWidth(title)} xs={css.celWidth(title)}>
      <Paper elevation={0} sx={css.paper(title)}>
        {title && <h4 sx={css.insightTitle(color)}>{title}</h4>}
        <span sx={css.insightNumber(color)}>{children}</span>
      </Paper>
    </Grid>
  );
}
