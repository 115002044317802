/** @jsx jsx */
import { CircularProgress, FormControlLabel, Grid, Switch } from '@material-ui/core';
import React from 'react';
import { jsx } from 'theme-ui';
import { ProductGraphBar } from '../../../models';
import { graphTitleCss, loaderCss } from '../../../styles';
import { GraphContainer } from '../../graphContainer';
import { ProductSoldGraph } from '../../productSoldGraph';
import { ProductsSoldTable } from '../../productSoldTable';

interface UnitSoldSectionProps {
  productsSold: ProductGraphBar[];
  isLoading: boolean;
}

export function UnitSoldSection(props: UnitSoldSectionProps) {
  const { productsSold, isLoading } = props;

  const [showTable, setShowTable] = React.useState<boolean>(true);

  return (
    <React.Fragment>
      {!!productsSold.length && (
        <Grid item xs={12} md={6} lg={6}>
          <GraphContainer>
            <FormControlLabel
              control={
                <Switch
                  checked={showTable}
                  onChange={(e, c) => setShowTable(c)}
                  color="primary"
                />
              }
              label={`Mostrar ${showTable ? 'grafico' : 'tabla'}`}
            />
            <h2 sx={graphTitleCss}>Variedades vendidas</h2>
            {isLoading && <CircularProgress sx={loaderCss} color="primary" />}
            {showTable ? (
              <ProductsSoldTable data={productsSold} />
            ) : (
              <ProductSoldGraph data={productsSold} />
            )}
          </GraphContainer>
        </Grid>
      )}
    </React.Fragment>
  );
}
