import { createMuiTheme } from '@material-ui/core';
import colors from './colors';

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.baked
    }
  }
});

export default appTheme;
export * from './commons';
