import { SxStyleProp } from 'theme-ui';

export const progressBar: SxStyleProp = {
  margin: '1rem 0',
  width: '100%'
};

export const salesContainer: SxStyleProp = {
  paddingTop: '1rem',
  paddingBottom: '2rem',
  maxWidth: '100vw'
};

export const salesContainerFlex: SxStyleProp = {
  flexDirection: ['column', 'row'],
  padding: '1rem 0',
  alignItems: 'center'
};

export const salesButton: SxStyleProp = {
  marginTop: '1.5rem',
  marginLeft: '1rem',
  marginBottom: ['1rem', '0rem']
};
