export const colors = {
  BICENTENARIO: '#ff7f0e',
  BELGRANO: '#1f77b4',
  GRANDBOURG: '#28a745',
  SANLORENZO: '#ff0000',
  PASEO_SALTA: '#9467bd',
  ZONA_SUR: '#8c564b'
};

export const generalColors = {
  SUCCESS: '#03B30A',
  FAIL: '#FF0000',
  GREY: '#E7E7E7'
};
