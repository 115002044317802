/** @jsx jsx */
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Flex, jsx } from 'theme-ui';
import { PaymentsSummary, StatsApi } from '../../api';
import { Filters, PosSelect } from '../../components';
import { paymentTypeNames } from '../../const';
import { Pos } from '../../models';
import * as css from './paymentFiltersCss';

export function PaymentsReport() {
  const initalDate = new Date();
  initalDate.setDate(initalDate.getDate() - 1);
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date>(initalDate);
  const [selectedToDate, setSelectedToDate] = React.useState<Date>(new Date());
  const [paymentsData, setPaymentsData] = React.useState<PaymentsSummary | undefined>();
  const [pos, setPos] = React.useState<Pos>(Pos.Belgrano);
  const [isLoading, setIsLoading] = React.useState(false);
  const fromDate = selectedFromDate.toISOString().substring(0, 10);
  const toDate = selectedToDate.toISOString().substring(0, 10);

  const handlePosChange = (pos: Pos) => {
    setPos(pos);
  };

  const { enqueueSnackbar } = useSnackbar();

  const getStocks = async () => {
    try {
      setIsLoading(true);
      const payments = await StatsApi.getPaymentsSummary(fromDate, toDate, pos as string);
      setPaymentsData(payments);
    } catch (error) {
      enqueueSnackbar('Algo salio mal 😢', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Container sx={css.transactionsContainer}>
        <Flex sx={css.transactionsContainerFlex}>
          <Filters
            selectedFromDate={selectedFromDate}
            setSelectedToDate={setSelectedToDate}
            setSelectedFromDate={setSelectedFromDate}
            selectedToDate={selectedToDate}
          />
          <div sx={{ ml: [0, '1rem'], mt: ['1rem', 0] }}>
            <PosSelect onSelect={handlePosChange} value={pos} />
          </div>
          <Grid item lg={2}>
            <Button
              onClick={getStocks}
              sx={css.actionButton}
              color="primary"
              variant="contained"
            >
              CONSULTAR
            </Button>
          </Grid>
        </Flex>
        <Grid container> {isLoading && <LinearProgress sx={css.progressBar} />}</Grid>
        <Grid container>
          <h2 sx={{ textAlign: 'center', width: '100%' }}>
            {' '}
            Resumen de pagos: {pos.replace('_', ' ')}{' '}
          </h2>{' '}
        </Grid>

        {!!paymentsData && (
          <Grid container spacing={2} sx={{ pt: '1rem' }}>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: '70vh', maxWidth: ['100%', '70vw', '40vw'], mx: 'auto' }}
            >
              <Table
                stickyHeader
                aria-label="collapsible table"
                size="small"
                sx={css.tableCss}
              >
                <TableHead>
                  <TableRow sx={css.tableBorderCss}>
                    <TableCell align="center">
                      <b>Tipo de Pago</b>
                    </TableCell>
                    <TableCell sx={css.tableHeaderCss} align="right">
                      <b>Monto</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(paymentsData).map((k, i) => {
                    const value = paymentsData[k as keyof PaymentsSummary];
                    if (value !== 0)
                      return (
                        <TableRow>
                          <TableCell align="center">
                            {paymentTypeNames[k.toLowerCase()]}
                          </TableCell>
                          <TableCell align="center">${value}</TableCell>
                        </TableRow>
                      );
                    return null;
                  })}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={css.tableHeaderCss}>
                      Total
                    </TableCell>
                    <TableCell align="center" sx={css.tableHeaderCss}>
                      $
                      {paymentsData.cash +
                        paymentsData.cashIn +
                        paymentsData.ccard +
                        paymentsData.cheque +
                        paymentsData.cashOut}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  );
}
