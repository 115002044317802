import { SxStyleProp } from 'theme-ui';

export const graphTitleCss: SxStyleProp = {
  marginTop: 0
};

export const loaderCss: SxStyleProp = {
  top: '50%',
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)'
};
