import { SxStyleProp } from 'theme-ui';

export const flexWrapper: SxStyleProp = {
  justifyContent: 'flex-end',
  mb: '1rem'
};

export const tableHeaderCss: SxStyleProp = {
  fontWeight: 'bold',
  color: 'black',
  textAlign: 'center'
};

export const tableHeight: SxStyleProp = {
  maxHeight: '20rem'
};

export const tableWidth: SxStyleProp = {
  width: '100%'
};

export const maxWidth: SxStyleProp = {
  maxWidth: '9.375rem'
};

export const alignTextCenter: SxStyleProp = {
  textAlign: 'center'
};

export const hiddenOverflow: SxStyleProp = {
  overflow: 'hidden'
};

export const loadingFlex: SxStyleProp = {
  justifyContent: 'flex-end',
  alignItems: 'center'
};
