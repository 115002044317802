/**@jsx jsx */
import { jsx } from 'theme-ui';
import { formatCurrentWeekDay } from '../../utils';
import * as css from './dataSliceTooltipCss';

interface SliceTooltipProps {
  slice: any;
  isMoney: boolean;
  hideTotal?: boolean;
}

export function DatesSliceTooltip(props: SliceTooltipProps) {
  const { slice, isMoney, hideTotal } = props;
  let total = 0;
  //@ts-ignore
  slice.points.map(p => (total += p.data.y));
  return (
    <div sx={css.wrapper}>
      <strong>
        {formatCurrentWeekDay(slice.points[0].data.x)} (
        {slice.points[0].data.x.toString().substring(0, 5)})
      </strong>
      {slice.points.map((point: any) => (
        <div key={point.id} sx={css.slice(point.serieColor)}>
          <strong>{point.serieId}</strong>{' '}
          <strong sx={css.marginLeft}>{point.data.yFormatted}</strong>
        </div>
      ))}
      {!hideTotal && (
        <strong>
          TOTAL: {isMoney && '$'}
          {isMoney ? parseFloat(`${total}`).toFixed(2) : parseInt(`${total}`, 10)}
        </strong>
      )}
    </div>
  );
}
