/** @jsx jsx */
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Fragment } from 'react';
import { jsx } from 'theme-ui';

interface CrudTableRowActionsProps {
  onEditClick: () => void;
  onRemoveClick?: () => void;
  hideDelete?: boolean;
}

export function CrudTableRowActions(props: CrudTableRowActionsProps) {
  const { onEditClick, onRemoveClick, hideDelete } = props;
  const handleRemove = () => {
    if (onRemoveClick) onRemoveClick();
  };
  return (
    <Fragment>
      <IconButton size="small" aria-label="edit" onClick={onEditClick}>
        <EditIcon fontSize="small" />
      </IconButton>
      {!hideDelete && (
        <IconButton size="small" aria-label="delete" onClick={handleRemove}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </Fragment>
  );
}
