import { SxStyleProp } from 'theme-ui';

export const formCss: SxStyleProp = {
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
};

export const formOptionCss: SxStyleProp = {
  mb: '1rem',
  width: ['100%', '48%']
};

export const descriptionWidth: SxStyleProp = {
  width: '100%'
};

export const flexEnd: SxStyleProp = {
  justifyContent: 'flex-end'
};
