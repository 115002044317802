import { format, startOfMonth, endOfDay, startOfDay } from 'date-fns';

export function formatDate(date: Date) {
  return format(date, 'dd/MM/yyyy');
}

export function formatDateAndTime(date: Date) {
  return format(date, 'dd/MM H:mm');
}

export function getTodayDate(time?: 'begining' | 'end') {
  const today = new Date();
  if (time === 'end') return endOfDay(today);
  if (time === 'begining') return startOfDay(today);
  return new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    today.getHours(),
    today.getMinutes()
  );
}

export function getBeginingOfTheMonth() {
  return startOfMonth(new Date());
}
