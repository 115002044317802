/** @jsx jsx */
import { Paper } from '@material-ui/core';
import { ReactNode } from 'react';
import { jsx, SxStyleProp } from 'theme-ui';

interface GraphContainerProps {
  children: ReactNode;
  className?: string;
}

const graphWrapperCss: SxStyleProp = {
  padding: ['1rem', '2rem'],
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
  minHeight: '450px',
  position: 'relative'
};

export function GraphContainer(props: GraphContainerProps) {
  const { children, className } = props;
  return (
    <Paper sx={graphWrapperCss} className={className}>
      {children}
    </Paper>
  );
}
