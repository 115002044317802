export enum PaymentType {
  CASH = 'cash',
  CASH_OUT = 'cashout',
  CREDIT_CARD = 'ccard',
  CASH_IN = 'cashin',
  FREE = 'free',
  VOUCHER = 'voucher',
  CHEQUE = 'cheque'
}

export const paymentTypeNames: { [key: string]: string } = {
  [PaymentType.CASH]: 'Venta Efectivo',
  [PaymentType.CASH_OUT]: 'Salida Efectivo',
  [PaymentType.CREDIT_CARD]: 'Pago con Tarjeta',
  [PaymentType.CASH_IN]: 'Entrada de Efectivo',
  [PaymentType.FREE]: 'Gratis',
  [PaymentType.VOUCHER]: 'Voucher',
  [PaymentType.CHEQUE]: 'Cheque'
};
