import { SxStyleProp } from 'theme-ui';
import { InsightColors } from '.';
import { colors } from '../../utils';

const colorMap = {
  totals: 'black',
  bicentenario: colors.BICENTENARIO,
  belgrano: colors.BELGRANO,
  grandBourg: colors.GRANDBOURG,
  sanLorenzo: colors.SANLORENZO,
  paseoSalta: colors.PASEO_SALTA,
  zonaSur: colors.ZONA_SUR
};

export const insightNumber = (color: InsightColors): SxStyleProp => ({
  color: colorMap[color],
  fontSize: ['1rem', '1.5rem'],
  fontWeight: 'normal'
});

export const insightTitle = (color: InsightColors): SxStyleProp => ({
  color: colorMap[color],
  fontSize: '0.9375rem',
  margin: '0'
});

export const paper = (title?: string): SxStyleProp => ({
  height: title ? '6rem' : '3rem',
  padding: '0.3rem',
  position: 'relative',
  textAlign: 'center',
  lineHeight: '2.5rem'
});

export const container: SxStyleProp = {
  marginTop: ['0.5rem', '1.5rem'],
  marginBottom: '2rem'
};

export const title: SxStyleProp = {
  textAlign: 'center',
  textTransform: 'uppercase'
};

export const celWidth = (title?: string) => (title ? 6 : 12);
