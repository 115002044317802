import { SxStyleProp } from 'theme-ui';

export const companyLogoCss: SxStyleProp = {
  margin: '1rem',
  width: '6.25rem'
};

export const paper: SxStyleProp = {
  marginTop: '8rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const form: SxStyleProp = {
  width: '100%', // Fix IE 11 issue.
  marginTop: '1rem'
};

export const error: SxStyleProp = {
  color: 'red',
  fontSize: '16px',
  marginBottom: '0'
};

export const submit: SxStyleProp = {
  margin: '3rem 0 2rem 0'
};
