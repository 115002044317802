import {
  StatsClient,
  UserClient,
  ExpensesClient,
  ExpensesCategoryClient,
  BranchesClient,
  ReportClient
} from './nswag.generated.api';
import { ENV } from '../config';
import { middleware } from './middleware';
export * from './nswag.generated.api';

export const StatsApi = new StatsClient(ENV.apiBaseUrl, middleware);
export const UsersApi = new UserClient(ENV.apiBaseUrl, middleware);
export const ExpensesApi = new ExpensesClient(ENV.apiBaseUrl, middleware);
export const ExpensesCategoryApi = new ExpensesCategoryClient(ENV.apiBaseUrl, middleware);
export const BranchesApi = new BranchesClient(ENV.apiBaseUrl, middleware);
export const ReportApi = new ReportClient(ENV.apiBaseUrl, middleware);
