/**@jsx jsx */
import { jsx } from 'theme-ui';
import { Pos } from '../../models';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { SxStyleProp } from 'theme-ui';

interface PosSelectProps {
  onSelect: (s: Pos) => void;
  value: Pos;
  /** Should the select show the option to select all branches? */
  showAll?: boolean;
}

//Component Styles
const posSelectWrapper: SxStyleProp = {
  display: 'inline-block',
  width: '7rem'
};

export function PosSelect(props: PosSelectProps) {
  const { onSelect, value, showAll } = props;
  const handlePosChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    onSelect(e.target.value as Pos);
  };
  return (
    <FormControl sx={posSelectWrapper}>
      <InputLabel id="label">Punto de venta</InputLabel>
      <Select id="demo-simple-select" value={value} onChange={handlePosChange}>
        {showAll && <MenuItem value={Pos.Todos}>Todos</MenuItem>}
        <MenuItem value={Pos.Belgrano}>Belgrano</MenuItem>
        <MenuItem value={Pos.Bicentenario}>Bicentenario</MenuItem>
        <MenuItem value={Pos.GrandBourg}>Grand Bourg</MenuItem>
        <MenuItem value={Pos.SanLorenzo}>San Lorenzo</MenuItem>
        <MenuItem value={Pos.PaseoSalta}>Paseo Salta</MenuItem>
        <MenuItem value={Pos.Factory}>Fabrica</MenuItem>
      </Select>
    </FormControl>
  );
}
