/**@jsx jsx */
import { CircularProgress } from '@material-ui/core';
import { LatLng } from 'geolocation-utils';
import GoogleMapReact, { Coords } from 'google-map-react';
import { useState } from 'react';
import { jsx } from 'theme-ui';
import * as css from './mapCss';

export type ContainerColor = string;
export type ContainerRadius = number;

interface DotProps {
  lat: number;
  lng: number;
  radius: ContainerRadius;
  color: ContainerColor;
  order?: string;
  address?: string;
  phone?: string;
  comments?: string;
}

function Dot(props: DotProps) {
  const { color, radius, order, address, phone, comments } = props;
  const [showDetails, setShowDetails] = useState<boolean>(false);

  if (order) {
    return (
      <div>
        <div sx={css.dotContainer(color, radius)} />
        {!!order && (
          <div sx={css.orderText} onClick={() => setShowDetails(!showDetails)}>
            <span>
              <b>{order}</b>
            </span>
            {showDetails && (
              <span>
                <br />
                {address}
                <br />
                {phone}
                <br />
                {comments}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
  return <div sx={css.dotContainer(color, radius)} />;
}

interface MapProps {
  points: AddressPoint[];
  loading: boolean;
  showPos: boolean;
}

export interface AddressPoint extends LatLng {
  order?: string;
  addressStreet?: string;
  phoneNumber?: string;
  comments?: string;
}

interface Location extends LatLng {
  color: string;
  name: string;
}

const locations: Location[] = [
  {
    lat: -24.786379,
    lng: -65.423172,
    color: 'blue',
    name: 'Belgrano'
  },
  {
    lat: -24.777653,
    lng: -65.403327,
    color: 'orange',
    name: 'Tres Cerritos'
  },
  {
    lat: -24.7806076,
    lng: -65.4466462,
    color: 'purple',
    name: 'Grand Bourg'
  },
  {
    lat: -24.735543972973964,
    lng: -65.48701037324003,
    name: 'San Lorenzo',
    color: 'coral'
  },
  {
    lat: -24.831330636336258,
    lng: -65.4291222597437,
    color: 'brown',
    name: 'Paseo Salta'
  }
];

export function LocationsMap(props: MapProps) {
  let { points, loading, showPos } = props;
  points = points.filter(p => p.lat !== -1);
  if (!points.length && !loading)
    return (
      <h2>
        No hay informacion para mostrar, no se han cargado ubicaciones en el periodo
        seleccionado para esta sucursal
      </h2>
    );
  if (!points.length && loading) return <CircularProgress color="primary" />;
  const center: Coords = { lat: -24.780632, lng: -65.422007 };
  return (
    // Important! Always set the container height explicitly
    <div sx={css.container}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDPcg1OkdG08lax9g85YXL7kZ0T2V0hBsI' }}
        defaultCenter={center}
        defaultZoom={13}
      >
        {points.map((p, i) => (
          <Dot
            color="red"
            radius={10}
            key={`${p.lat}${p.lng}${i}`}
            lat={p.lat}
            lng={p.lng}
            order={p.order}
            address={p.addressStreet}
            phone={p.phoneNumber}
            comments={p.comments}
          />
        ))}
        {showPos &&
          locations.map(l => <Dot color={l.color} radius={15} lat={l.lat} lng={l.lng} />)}
      </GoogleMapReact>
    </div>
  );
}
