/** @jsx jsx  */
import { jsx } from 'theme-ui';
import { ReactNode } from 'react';
import { ExpensesContextProvider } from './expensesContext';

interface BussinesPulseContextProviderProps {
  children: ReactNode;
}
/** ## State managment
 * Lets create specific contexts with small typed states and group them here so they are
 * available for the whole application
 */
export function BussinesPulseContextProvider(props: BussinesPulseContextProviderProps) {
  const { children } = props;
  return <ExpensesContextProvider>{children}</ExpensesContextProvider>;
}
