import { SxStyleProp } from 'theme-ui';

export const comparisonContainer: SxStyleProp = {
  paddingTop: '1rem',
  paddingBottom: '2rem',
  maxWidth: '100vw'
};

export const comparisonH2: SxStyleProp = {
  textAlign: ['center', 'left']
};

export const comparisonFlex: SxStyleProp = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: ['column', 'row'],
  marginBottom: '1rem'
};

export const comparisonFormControl: SxStyleProp = {
  display: 'inline-block',
  width: '7rem',
  ml: '1rem',
  mr: '1rem'
};

export const comparisonButton: SxStyleProp = {
  ml: '1rem',
  mt: ['.5rem', 'unset']
};

export const comparisonLoading: SxStyleProp = {
  margin: '1rem 0',
  width: '100%'
};
