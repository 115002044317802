/**@jsx jsx */
import { jsx } from 'theme-ui';
import { ProductGraphBar } from '../../models';
import { SxStyleProp } from 'theme-ui';

//component styles
const titleCss: SxStyleProp = {
  textAlign: 'center'
};
const textColor = (color: string): SxStyleProp => ({
  color
});
//

export const BarTooltip = (data: ProductGraphBar) => {
  const {
    name,
    reference,
    belgrano,
    bicentenario,
    belgranoColor,
    bicentenarioColor,
    grandBourg,
    grandBourgColor,
    paseoSalta,
    paseoSaltaColor,
    zonaSur,
    zonaSurColor,
    sanLorenzo,
    sanLorenzoColor
  } = data;

  return (
    <div>
      <h4 sx={titleCss}>
        {name} ({reference})
      </h4>
      <strong sx={textColor(belgranoColor)}>BELGRANO: {belgrano}</strong> <br />
      <strong sx={textColor(paseoSaltaColor)}>PASEO SALTA: {belgrano}</strong> <br />
      <strong sx={textColor(bicentenarioColor)}>TRES CERRITOS: {bicentenario}</strong>
      <br />
      <strong sx={textColor(grandBourgColor)}>GRAND BOURG: {grandBourg}</strong> <br />
      <strong sx={textColor(zonaSurColor)}>ZONA SUR: {zonaSur}</strong> <br />
      <strong sx={textColor(sanLorenzoColor)}>SAN LORENZO: {sanLorenzo}</strong> <br />
      <strong sx={textColor('black')}>
        TOTAL: {bicentenario + belgrano + paseoSalta + sanLorenzo + zonaSur + grandBourg}
      </strong>{' '}
      <br />
    </div>
  );
};
