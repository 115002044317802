/**@jsx jsx */
import { Button, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { Flex, jsx } from 'theme-ui';
import { IExpenseCategoryDTO } from '../../../api';
import { Combobox } from '../../comboBox';
import { useExpenseCategoriesRHF } from './useExpenseCategoriesRHF';
import { SxStyleProp } from 'theme-ui';

interface ExpensesCategoriesFormProps {
  selectedCategory?: IExpenseCategoryDTO;
  onSubmit?: (category: IExpenseCategoryDTO) => void;
}

//Component CSS Styles
const formStyle: SxStyleProp = {
  width: ['100%', '30rem']
};
const formOptionMb: SxStyleProp = {
  mb: '1rem'
};
const buttonStyle: SxStyleProp = {
  justifyContent: 'flex-end'
};
//

export function ExpensesCategoriesForm(props: ExpensesCategoriesFormProps) {
  const { selectedCategory, onSubmit } = props;

  const { onFormSubit, categories, hookform } = useExpenseCategoriesRHF(
    selectedCategory,
    onSubmit
  );

  const { formState, handleSubmit, errors, control, register } = hookform;

  return (
    <form sx={formStyle} onSubmit={handleSubmit(onFormSubit)}>
      <TextField
        name="name"
        fullWidth
        label="Nombre"
        error={!!errors.name}
        helperText={errors.name?.message}
        disabled={formState.isSubmitting}
        variant="outlined"
        sx={formOptionMb}
        inputRef={register}
      />
      <Controller
        control={control}
        name="parentCategoryId"
        render={props => (
          <Combobox
            options={categories}
            getOptionLabel={c => c?.name ?? ''}
            onChange={(e, v) => {
              props.onChange(v?.id ?? null);
            }}
            placeholder="Seleccionar categoria madre"
            label="Categoria madre"
            sx={formOptionMb}
            noOptionsText="No hay categorias"
            defaultValue={selectedCategory?.category ?? null}
            error={!!errors.parentCategoryId}
            helperText={errors.parentCategoryId?.message}
          />
        )}
      />
      <TextField
        name="description"
        fullWidth
        label="Description"
        variant="outlined"
        inputRef={register}
        multiline
        rows={3}
        sx={formOptionMb}
      />
      <Flex sx={buttonStyle}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={formState.isSubmitting || !formState.isDirty}
        >
          Guardar
        </Button>
      </Flex>
    </form>
  );
}
