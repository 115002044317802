import { SxStyleProp } from 'theme-ui';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import colors from '../../styles/colors';

const drawerWidth = '15rem';

export const drawer: SxStyleProp = {
  width: drawerWidth,
  flexShrink: 0
};
export const drawerHeader: SxStyleProp = {
  width: drawerWidth,
  display: 'flex',
  alignItems: 'center',
  padding: '0 0.5rem',
  justifyContent: 'flex-start',
  backgroundColor: 'black',
  minHeight: '4rem'
};
export const activeStyle: CSSProperties = {
  color: colors.baked,
  fontWeight: 'bold'
};
export const navLinkCss: SxStyleProp = {
  textDecoration: 'none',
  color: 'black'
};
export const details: SxStyleProp = {
  display: 'flex',
  flexDirection: 'column',
  padding: '0'
};
export const summary: SxStyleProp = {
  padding: '0 1rem',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr'
};
export const expansionPanel: SxStyleProp = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderBottom: '1px solid lightgray',
  width: '100%'
};
export const button: SxStyleProp = {
  color: colors.baked
};
export const expandButton: SxStyleProp = {
  justifySelf: 'end'
};
export const betaMenuItem: SxStyleProp = { overflow: 'visible' };
