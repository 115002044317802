/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactNode, useEffect, useState } from 'react';
import { iconButton, modal, dialogContent } from './useModalCss';

interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
  title?: string;
}

function Modal(props: ModalProps) {
  const { children, open, title, onClose } = props;
  const [openState, setOpenState] = useState(false);
  useEffect(() => {
    setOpenState(open);
  }, [open]);
  const handleClose = () => {
    setOpenState(false);
    if (onClose) onClose();
  };
  return (
    <Dialog open={openState} sx={modal}>
      <DialogTitle id="alert-dialog-slide-title">
        {title}
        <IconButton aria-label="close" sx={iconButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={dialogContent}>{children}</DialogContent>
    </Dialog>
  );
}

export function useModal() {
  const [open, setOpen] = useState(false);
  const ControlledModal = (props: Omit<ModalProps, 'open'>) => {
    return (
      <Modal onClose={() => setOpen(false)} open={open} title={props.title}>
        {props.children}
      </Modal>
    );
  };

  return {
    Modal: ControlledModal,
    openModal: () => setOpen(true),
    closeModal: () => setOpen(false)
  };
}
