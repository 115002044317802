import React, { useContext, useState } from 'react';
import { IBranchDTO, IExpenseCategoryDTO, IExpenseDTO } from '../api';

type AsyncEntityList<T> = {
  values: T[];
  isLoading: boolean;
};

interface ExpensesList extends AsyncEntityList<IExpenseDTO> {
  totalAmount?: number;
}

interface ExpensesState {
  categories: AsyncEntityList<IExpenseCategoryDTO>;
  branches: AsyncEntityList<IBranchDTO>;
  expenses: ExpensesList;
}

export const EXPENSES_STATE = {
  categories: { values: [], isLoading: false },
  branches: { values: [], isLoading: false },
  expenses: { values: [], isLoading: false }
};

const ExpensesContext = React.createContext<[ExpensesState, (s: ExpensesState) => void]>([
  EXPENSES_STATE,
  () => null
]);

interface ExpensesContextProviderProps {
  children: React.ReactNode;
}

export function ExpensesContextProvider(props: ExpensesContextProviderProps) {
  const [expensesState, setExpensesState] = useState<ExpensesState>(EXPENSES_STATE);

  function updateState(state: ExpensesState) {
    setExpensesState({ ...state });
  }

  return (
    <ExpensesContext.Provider value={[expensesState, updateState]}>
      {props.children}
    </ExpensesContext.Provider>
  );
}

export const useExpensesState = () => useContext(ExpensesContext);
