/** @jsx jsx */
import { Container, Grid } from '@material-ui/core';
import { jsx } from 'theme-ui';
import { StockLevelsSection } from '../../components';
import { SxStyleProp } from 'theme-ui';

//Component Styles
const stockContainer: SxStyleProp = {
  paddingTop: '1rem',
  paddingBottom: '2rem',
  maxWidth: '100vw'
};
//

export function StockControl() {
  return (
    <Container sx={stockContainer}>
      <Grid container spacing={2}>
        <StockLevelsSection />
      </Grid>
    </Container>
  );
}
