/** @jsx jsx */
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Flex, jsx } from 'theme-ui';
import { useModal } from '../../../hooks';
import { formatDate } from '../../../utils';
import { ExpensesForm } from '../expensesForm';
import { ExpensesRowActions } from './expensesRowActions';
import { useExpensesFitlerAndPagination } from './useExpensesFilters';
import { useExpenseUpdate } from './useExpensesUpdate';
import * as css from './expensesCrudTableCss';

export function ExpensesCrudTable() {
  const { Modal, openModal } = useModal();
  const {
    expState,
    ExpensesFilters,
    ExpensesPagination
  } = useExpensesFitlerAndPagination();
  const { updateState } = useExpenseUpdate();
  const { values: expenses, isLoading, totalAmount } = expState.expenses;
  return (
    <div>
      <Flex sx={css.flexWrapper}>
        <Button color="primary" variant="contained" onClick={openModal}>
          Agregar Costo
        </Button>
        <Modal title="Registrar compra">
          <ExpensesForm onSubmit={updateState} />
        </Modal>
      </Flex>
      <ExpensesFilters />
      <TableContainer sx={css.tableHeight}>
        <Table stickyHeader size="small" sx={css.tableWidth}>
          <TableHead>
            <TableRow>
              <TableCell sx={css.tableHeaderCss}>Fecha</TableCell>
              <TableCell sx={css.tableHeaderCss}>Sucursal</TableCell>
              <TableCell sx={css.tableHeaderCss}>Categoria</TableCell>
              <TableCell sx={css.tableHeaderCss}>Monto</TableCell>
              <TableCell sx={{ ...css.tableHeaderCss, ...css.maxWidth }}>
                Descripcion
              </TableCell>
              <TableCell sx={css.tableHeaderCss}>Responsable</TableCell>
              <TableCell sx={css.tableHeaderCss}>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              expenses?.map((expense, i) => {
                return (
                  <TableRow key={i} hover>
                    <TableCell
                      padding="none"
                      sx={css.alignTextCenter}
                      component="th"
                      scope="row"
                    >
                      {formatDate(expense.expenseDate)}
                    </TableCell>
                    <TableCell
                      padding="none"
                      sx={css.alignTextCenter}
                      component="th"
                      scope="row"
                    >
                      {expense?.branch?.name}
                    </TableCell>
                    <TableCell
                      padding="none"
                      sx={{
                        ...css.alignTextCenter,
                        ...css.maxWidth,
                        ...css.hiddenOverflow
                      }}
                      align="right"
                    >
                      {expense.category?.name}
                    </TableCell>
                    <TableCell padding="none" sx={css.alignTextCenter} align="right">
                      ${expense.amount}
                    </TableCell>
                    <TableCell
                      padding="none"
                      sx={{
                        ...css.alignTextCenter,
                        ...css.maxWidth,
                        ...css.hiddenOverflow
                      }}
                      align="right"
                    >
                      {expense.description}
                    </TableCell>
                    <TableCell padding="none" sx={css.alignTextCenter} align="right">
                      <abbr title={`Modificado el ${formatDate(expense.lastModified)}`}>
                        {expense?.responsible?.name}
                      </abbr>
                    </TableCell>
                    <TableCell padding="none" sx={css.alignTextCenter} align="right">
                      <ExpensesRowActions selectedExpense={expense} />
                    </TableCell>
                  </TableRow>
                );
              })}
            {(!expenses?.length || isLoading) && (
              <TableRow>
                <TableCell colSpan={8}>
                  <p sx={css.alignTextCenter}>
                    {isLoading ? 'Cargando...' : 'No hay costos para mostrar'}
                  </p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Flex sx={css.loadingFlex}>
        {!isLoading && (
          <Flex>
            Monto total: &nbsp; <strong> ${totalAmount}</strong>
          </Flex>
        )}
        <ExpensesPagination />
      </Flex>
    </div>
  );
}
