/**@jsx jsx */
import { CartesianMarkerProps } from '@nivo/core';
import { DataFormatter, Line, LineSerieData } from '@nivo/line';
import maxBy from 'lodash.maxby';
import { jsx, SxStyleProp } from 'theme-ui';
import { DatesSliceTooltip } from '../dataSliceTooltip';

//Component Styles
const lineGraphWrapper = (dataLength: number, spacing: number): SxStyleProp => ({
  width: `${dataLength * spacing + 350}px`
});

interface LineGraphProps {
  data: LineSerieData[];
  isMoney?: boolean;
  yFormat?: string | DataFormatter;
  markers?: CartesianMarkerProps[];
  lineSpacing?: number;
  hideTotalInTooltip?: boolean;
}

export function LineGraph(props: LineGraphProps) {
  const { data, isMoney, yFormat, markers, lineSpacing, hideTotalInTooltip } = props;
  const spacing = lineSpacing || 35;
  if (data.length) {
    const setWithMaxLength = maxBy(data, o => o.data.length);
    const dataLength = setWithMaxLength ? setWithMaxLength.data.length : 0;

    return (
      <div sx={lineGraphWrapper(dataLength, spacing)}>
        <Line
          width={dataLength * spacing + 150}
          height={400}
          margin={{ top: 20, right: 20, bottom: 60, left: 80 }}
          yScale={{
            type: 'linear'
          }}
          curve="linear"
          animate
          enablePoints
          pointSize={10}
          enableSlices="x"
          markers={markers}
          data={data}
          axisLeft={{
            format: value => `${isMoney ? '$' + value : value}`
          }}
          axisBottom={{
            format: value => `${value.toString().substring(0, 5)}`
          }}
          yFormat={yFormat}
          colors={{ scheme: 'category10' }}
          // @ts-ignore
          sliceTooltip={({ slice }) => (
            <DatesSliceTooltip
              slice={slice}
              isMoney={!!isMoney}
              hideTotal={hideTotalInTooltip}
            />
          )}
        />
      </div>
    );
  }
  return null;
}
