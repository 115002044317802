import { SxStyleProp } from 'theme-ui';

export const tableCss: SxStyleProp = {
  fontSize: '14px'
};

export const tableHeaderCss: SxStyleProp = {
  padding: '10px'
};

export const tableBorderCss: SxStyleProp = {
  borderBottom: '3px solid #C4C4C4'
};

export const graphWrapperCss: SxStyleProp = {
  padding: '2rem',
  display: 'flex',
  overflow: 'auto',
  flexDirection: ['column', 'row'],
  minHeight: '450px',
  position: 'relative',
  marginTop: '2rem'
};
