import { useState } from 'react';
import { SalesByDay, StatsApi } from '../../../api';
import { EMPANADAS } from '../../../const';
import { Pos } from '../../../models';

export function useSalesByMonth(fromDate: string, toDate: string) {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalSales, setTotalSales] = useState<SalesByDay[]>([]);
  const [totalUnits, setTotalUnits] = useState<SalesByDay[]>([]);

  const handleQueryClick = async () => {
    setIsLoading(true);
    try {
      const bicentenarioSalesPromise = StatsApi.getSalesByMonth(
        fromDate,
        toDate,
        Pos.Bicentenario
      );
      const bicentenarioTotalsPromise = StatsApi.getUnitsSoldByMonth(
        fromDate,
        toDate,
        EMPANADAS,
        Pos.Bicentenario
      );

      const belgranoSalesPromise = StatsApi.getSalesByMonth(
        fromDate,
        toDate,
        Pos.Belgrano
      );
      const belgranoTotalsPromise = StatsApi.getUnitsSoldByMonth(
        fromDate,
        toDate,
        EMPANADAS,
        Pos.Belgrano
      );

      const gbSalesPromise = StatsApi.getSalesByMonth(fromDate, toDate, Pos.GrandBourg);
      const gbTotalsPromise = StatsApi.getUnitsSoldByMonth(
        fromDate,
        toDate,
        EMPANADAS,
        Pos.GrandBourg
      );

      const sanLorenzoSalesPromise = StatsApi.getSalesByMonth(
        fromDate,
        toDate,
        Pos.SanLorenzo
      );
      const sanLorenzoTotalsPromise = StatsApi.getUnitsSoldByMonth(
        fromDate,
        toDate,
        EMPANADAS,
        Pos.SanLorenzo
      );

      const paseoSaltaSalesPromise = StatsApi.getSalesByMonth(
        fromDate,
        toDate,
        Pos.PaseoSalta
      );
      const paseoSaltaTotalsPromise = StatsApi.getUnitsSoldByMonth(
        fromDate,
        toDate,
        EMPANADAS,
        Pos.PaseoSalta
      );

      const [
        belgranoSales,
        belgranoTotals,
        bicentenarioSales,
        bicentenarioTotals,
        gbSales,
        gbTotals,
        slSales,
        slTotals,
        paseoSales,
        paseoTotals
      ] = await Promise.all([
        belgranoSalesPromise,
        belgranoTotalsPromise,
        bicentenarioSalesPromise,
        bicentenarioTotalsPromise,
        gbSalesPromise,
        gbTotalsPromise,
        sanLorenzoSalesPromise,
        sanLorenzoTotalsPromise,
        paseoSaltaSalesPromise,
        paseoSaltaTotalsPromise
      ]);

      setTotalSales([belgranoSales, bicentenarioSales, gbSales, slSales, paseoSales]);
      setTotalUnits([
        belgranoTotals,
        bicentenarioTotals,
        gbTotals,
        slTotals,
        paseoTotals
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setError('Ups! Algo salio mal. \n Volve a intentar en unos instantes');
      setIsLoading(false);
    }
  };
  return { handleQueryClick, error, setError, isLoading, totalSales, totalUnits };
}
