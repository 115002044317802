import { SxStyleProp } from 'theme-ui';

export const container1: SxStyleProp = {
  display: 'flex',
  justifyContent: 'flex-start'
};

export const container2: SxStyleProp = {
  display: 'flex',
  justifyContent: 'space-around',
  flexDirection: ['column', 'row']
};

export const button: SxStyleProp = {
  marginTop: '1.5rem',
  marginLeft: '1rem',
  marginBottom: ['1rem', '0rem']
};

export const salesByMonthML: SxStyleProp = {
  marginLeft: '1rem'
};
