import { SxStyleProp } from 'theme-ui';
import { ContainerColor, ContainerRadius } from '.';

export const container: SxStyleProp = {
  height: '25rem',
  width: '100%'
};

export const dotContainer = (
  color: ContainerColor,
  radius: ContainerRadius
): SxStyleProp => ({
  backgroundColor: color,
  width: `${radius || 10}px`,
  height: `${radius || 10}px`,
  borderRadius: '50%'
});

export const orderText: SxStyleProp = {
    backgroundColor: '#ffffff',
    width: 'max-content',
    borderRadius: '0.625rem',
    padding: '0.125rem 0.3125rem 0.125rem 0.3125rem'
}