/**@jsx jsx */
import { ResponsiveBar } from '@nivo/bar';
import { jsx } from 'theme-ui';
import { Pos, ProductGraphBar } from '../../models';
import { BarTooltip } from './barTooltip';

interface LineGraphProps {
  data: ProductGraphBar[];
}

export function ProductSoldGraph(props: LineGraphProps) {
  const { data } = props;
  if (data.length) {
    return (
      <div sx={{ height: '28.125rem', minWidth: '600px' }}>
        <ResponsiveBar
          data={data as object[]}
          colors={{ scheme: 'category10' }}
          keys={[
            'belgrano',
            'bicentenario',
            'grandBourg',
            'sanLorenzo',
            'paseoSalta',
            'zonaSur'
          ]}
          indexBy="reference"
          groupMode="stacked"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          enableLabel={false}
          tooltip={tooltipInfo => (
            <BarTooltip {...((tooltipInfo.data as unknown) as ProductGraphBar)} />
          )}
        />
      </div>
    );
  }

  return null;
}
